import React from "react";

import Layout from "../../components/Layout.jsx";
import ServiceCard from "../../components/ServiceCard.jsx";
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl";
import { parseXmlServices } from "../../utils";
import { graphql } from "gatsby";

export default ({ data, location }) => {
  const currentLocale = useIntl().locale;
  const services = parseXmlServices(data, currentLocale);

  return (
    <Layout location={location}>
      <section className="ftco-section bg-light">
        <div className="container">
          <div className="row">
            {services.map((service) => {
              return (
                <div className="col-12 col-md-6 col-lg-4 my-2">
                  <ServiceCard service={service} />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allServicesXml {
      edges {
        node {
          xmlChildren {
            children {
              name
              content
            }
            content
            name
          }
          name
        }
      }
    }
  }
`;
