import React from "react";
import * as styles from '../styles/modules/card.module.scss';
import { Link } from "gatsby-plugin-intl";

export default ({ image, title, children, imageHref }) => (
  <div className="card h-100">
    <div className="img-hover-zoom">
    <Link to={imageHref}><img src={image} className={`card-img-top ${styles.img_height}`} alt="..." /></Link>
    </div>
    <div className="card-body d-flex flex-column">
      <h5 className="card-title text-primary">{title}</h5>
      {children}
    </div>
  </div>
);
