import React from "react";
import Card from "../components/Card.jsx";
import { FormattedMessage, Link } from "gatsby-plugin-intl";

export default ({ service }) => (
    <Card title={<Link to={service.link}>{service.title}</Link>} image={require(`../images/services/${service.preview}`).default} imageHref={service.link}>
    <p className="card-text">{service.subtitle}</p>
    <Link to={service.link} className="btn btn-primary mt-auto">
      <FormattedMessage id="services.read_more"/>
    </Link>
</Card>
);
